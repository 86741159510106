export function fetchConfig (type = 'json') {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: `application/${type}` }
  }
}

export function debounce (fn, wait) {
  let t
  return (...args) => {
    clearTimeout(t)
    t = setTimeout(() => fn.apply(this, args), wait)
  }
}

export const trigger = (eventName, el, data = {}) => {
  let event
  if (typeof eventName === 'object') {
    data = eventName.data
    eventName = eventName.event
  }
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(eventName, { detail: data })
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, true, true, data)
  }
  el.dispatchEvent(event)
  return el
}